export class SuccessData {
  public Header: string = '';
  public Subheader: string = '';
  public PaymentAmount: PaymentAmount = new PaymentAmount();
  public DetailPreMessage: string = '';
  public Details: SuccessDetails[] = [];
  public DetailPostMessage: string = '';
  public CTAList: CTA[] = [];
  public LinkList: Link[] = [];
  public PostNotificatinoList: string[] = [];
}

export class PaymentAmount {
  public Label!: string;
  public Value!: number;
}

export class SuccessDetails {
  public Label!: string;
  public Value!: string;
}

export class Link {
  public Label!: string;
  public Link!: string;
  public AriaLabel!: string;
}

export class CTA {
  public Label!: string;
  public Link!: string;
  public AriaLabel!: string;
}
