/* eslint-disable @typescript-eslint/unbound-method */
import { Component } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ActivatedRoute, Router } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from '../../shared/services/util.service';
import { CommonModule } from '@angular/common';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { FormBuilder, FormControl, type FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { GSIconListBulletModel, GsIconListComponent, GSIconListModel } from 'gas-south';
import { catchError, map, throwError, type Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { CmsService } from '../../shared/services/cms.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from '../../shared/services/storage.service';
import { StorageEnum } from '../../shared/enums/storage.enum';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AuthService } from '../../shared/services/auth.service';
import { emailValidator } from '../../shared/validators/email.validator';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GsIconListComponent,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  pageLabels$!: Observable<any>;
  fupForm!: FormGroup;
  apiErrors: any[] = [];
  apiError: string = '';
  formErrorMessage: string = '';
  submitSuccess = false;
  showPasswordLinkExpiredHeader = false;

  constructor (
    private readonly auth: AuthService,
    private readonly cms: CmsService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly storage: StorageService,
    private readonly utils: UtilService,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit (): void {
    this.pageLabels$ = this.cms.getContent('forgot-username-password', 'Forgot')
      .pipe(
        map((data: any) => {
          const unmappedListData = data.bulletList.value.data;
          const listData = new GSIconListModel();
          listData.Header = unmappedListData.header;
          listData.Subheader = unmappedListData.subheader;

          const bulletList: GSIconListBulletModel[] = [];
          unmappedListData?.bulletList?.forEach((bullet: any) => {
            const bulletObj = new GSIconListBulletModel();
            bulletObj.BulletText = bullet.bullet.bulletText;
            bulletObj.BulletTitle = bullet.bullet.bulletTitle;
            bulletObj.IconPath = bullet.bullet.iconPath;
            bulletObj.IconAltText = bullet.bullet.iconAltText;
            bulletObj.ShowBullet = bullet.bullet.showBullet;

            bulletList.push(bulletObj);
          });

          listData.BulletList = bulletList;

          const newDataObj = JSON.parse(JSON.stringify(data));
          newDataObj.accountBenefitsList = listData;

          return newDataObj;
        })
      );

    if (this.checkForPasswordLinkExpired()) {
      this.showPasswordLinkExpiredHeader = true;
    }

    this.fupForm = this.formBuilder.group({
      email: ['', [Validators.required, emailValidator()]]
    });
    this.utils.hideSpinner();
  }

  get email (): FormControl {
    return this.fupForm.get('email') as FormControl;
  }

  onSubmit (): void {
    this.apiError = '';
    this.formErrorMessage = '';
    if (this.fupForm.valid) {
      this.utils.showSpinner();
      this.apiErrors = this.storage.getSession(StorageEnum.GlobalVars).apiErrors;
      // Handle form submission
      this.auth.forgotUsernamePassword(this.email.value).pipe(
        catchError((dataError: any) => {
          // check if status 500 - handle it
          /**
           * {
                "headers": {
                  "normalizedNames": {},
                  "lazyUpdate": null,
                  "headers": {}
                },
                "status": 500,
                "statusText": "Unknown Error",
                "url": "https://localhost:7264/api/authenticate/reset",
                "ok": false,
                "name": "HttpErrorResponse",
                "message": "Http failure response for https://localhost:7264/api/authenticate/reset: 500 ",
                "error": {
                  "type": "https://tools.ietf.org/html/rfc7231#section-6.6.1",
                  "title": "An error occurred while processing your request.",
                  "status": 500,
                  "traceId": "00-3987fa6d10f501574dbe7c9ac62c3e6c-a2cedf7b8900f8c0-00"
                }
              }
              const errStatus = dataError.error.status;
           */
          const errorCode = dataError.error.ErrorCode;
          const newApiError = this.apiErrors.find(error => {
            return error.apiError.errorCode === errorCode;
          });

          if (newApiError) {
            this.showAPIErrorLabel(newApiError.apiError.displayMessage);
          } else {
            this.utils.consoleLog('fup no api error found');
          }
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          this.utils.hideSpinner();
          return throwError(() => new Error(dataError));
        })).subscribe((data: any) => {
        this.utils.consoleGroup('fup submit data: ', data);
        if (data.userInfo.emailId) {
          this.submitSuccess = true;
        } else {
          // what do if empty for some reason?
          // TODO: show ugly err page
        }
        this.utils.hideSpinner();
      });
    } else {
      // Mark all controls as touched to trigger validation messages
      this.fupForm.markAllAsTouched();
      this.showFormErrorLabel();
    }
  }

  replaceEmail (emailMsg: string, email: string): string {
    return emailMsg.replace('{EMAIL}', email.trim());
  }

  logDebug (data: any): void {
    this.utils.consoleGroup('Login icon-list Debug Data', data);
  }

  async goToLogin (): Promise<void> {
    await this.router.navigateByUrl('/login');
  }

  showFormErrorLabel (): void {
    this.formErrorMessage = this.storage.getSession(StorageEnum.GlobalVars).formError;
    setTimeout(() => {
      const errorEl = document.getElementById('formErrorLabel');
      errorEl?.focus();
    }, 10);
  }

  showAPIErrorLabel (errMsg: string): void {
    this.apiError = errMsg;
    setTimeout(() => {
      const errorEl = document.getElementById('apiErrorLabel');
      errorEl?.focus();
    }, 10);
  }

  checkForPasswordLinkExpired (): boolean {
    const queryParams = this.route.snapshot.queryParams;
    const passwordLinkExpired = queryParams['passwordLinkExpired'];
    if (this.utils.isNullOrUndefined(passwordLinkExpired)) {
      return false;
    }
    if (passwordLinkExpired === 'true') {
      return true;
    }
    return false;
  }
}
