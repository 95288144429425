// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Component, Inject, Optional } from '@angular/core';
/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/unbound-method */
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, AbstractControl, ValidationErrors, Validators } from '@angular/forms';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Router } from '@angular/router';
// import { RxLoginForm } from './login.form';
import { GsButtonComponent } from 'gas-south';
// import { StorageEnum } from '../../../shared/enums/storage.enum';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from '../../../shared/services/util.service';
import { StorageService } from '../../../shared/services/storage.service';
import { StorageEnum } from '../../../shared/enums/storage.enum';
import { CmsService } from '../../../shared/services/cms.service';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { UserProfile } from '../../models/user-profile';
import { PaymentusBankMethod, PaymentusRequest } from '../../models/paymentus';
import { BankAccountType } from '../../enums/bankaccounttype.enum';
import { PaymentusService } from '../../services/paymentus.service';
import { PaymentMethod } from '../../models/payment-method';
import { PaymentAccountType } from '../../enums/paymentaccounttype.enum';
import { PaymentDialogResult } from '../../models/payment-dialog-result';
import { PaymentusErrorCode } from '../../enums/paymentuserrorcode.enum';
import { DialogResultType } from '../../enums/dialogresulttype.enum';

@Component({
  selector: 'app-bank-account-form',
  standalone: true,
  templateUrl: './bank-account-form.component.html',
  styleUrl: './bank-account-form.component.scss',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GsButtonComponent,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule
  ]
})
export class BankAccountFormComponent {
  pageLabels$!: Observable<any>;
  bankAccountForm!: FormGroup;
  apiErrors: any[] = [];
  apiError: string = '';
  formErrorMessage: string = '';
  showBankAccountCheckImage = false;
  userProfile?: UserProfile;
  prefferedPayMethods?: PaymentMethod[];
  showPrefSection = true;
  isWalletChecked = false;
  isParentPayment = false;

  constructor (
    @Optional() public dialogRef: MatDialogRef<BankAccountFormComponent>,
    private readonly cms: CmsService,
    private readonly formBuilder: FormBuilder,
    // eslint-disable-next-line @typescript-eslint/prefer-readonly
    private router: Router,
    private readonly utils: UtilService,
    private readonly storage: StorageService,
    private readonly paymentusSvc: PaymentusService,
    @Inject(MAT_DIALOG_DATA) data: string
  ) {
    this.isParentPayment = data === 'payment';
  }

  ngOnInit (): void {
    this.pageLabels$ = this.cms.getContent('oas-paymethod-modal', 'PaymethodModal');
    this.bankAccountForm = this.formBuilder.group({
      accountNumber: ['', [Validators.required, this.validateBankAccount.bind(this)]],
      routingNumber: ['', [Validators.required, this.validateBankRoutingNumber.bind(this)]],
      nickName: ['', [this.validateNickname.bind(this)]],
      accountType: ['Checking'],
      setDefault: [false],
      saveWallet: [false]
    });
    this.userProfile = this.storage.getSession(StorageEnum.LoginResponse);
    this.prefferedPayMethods = this.storage.getSession(StorageEnum.PaymentMethods);
    if (!this.isParentPayment) {
      this.isWalletChecked = true;
      this.bankAccountForm.get('saveWallet')?.setValue(true);
    }
    this.utils.hideSpinner();
  }

  onSubmit (): void {
    this.formErrorMessage = '';
    this.checkForDuplicateNickName();
    if (this.bankAccountForm.valid) {
      this.utils.showSpinner();
      this.savePaymentData().subscribe({
        error: (e) => {
          // get code from message string
          const [errorCode, message] = e.message.split('|').map(String);
          if (errorCode === PaymentusErrorCode.dialogCode) {
            this.showFormErrorLabel(message);
          } else if (errorCode === PaymentusErrorCode.ExternalPageCode) {
            const dialogResult = new PaymentDialogResult();
            dialogResult.Type = DialogResultType.Error;
            dialogResult.ErrorMessage = message;
            this.dialogRef.close(dialogResult);
          } else {
            this.formErrorMessage = this.storage.getSession(StorageEnum.GlobalVars).paymentusGenericError;
          }
          this.utils.hideSpinner();
        },
        next: (data: any) => {
          const dialogResult = new PaymentDialogResult();
          dialogResult.Type = DialogResultType.Add;
          dialogResult.PayMethod.ReferenceId = data['profile-response'].token;
          dialogResult.PayMethod.BankRoutingNumber = this.bankAccountForm.get('routingNumber')?.value;
          dialogResult.PayMethod.BankAccountNumber = this.bankAccountForm.get('accountNumber')?.value;
          dialogResult.PayMethod.BankAccountType = this.bankAccountForm.get('accountType')?.value;
          dialogResult.PayMethod.Nickname = this.bankAccountForm.get('nickName')?.value;
          dialogResult.PayMethod.IsDefault = this.bankAccountForm.get('setDefault')?.value;
          dialogResult.PayMethod.SaveToAccount = this.bankAccountForm.get('saveWallet')?.value;
          let lastFour = data['profile-response']['account-number'];
          lastFour = lastFour.slice(lastFour.indexOf('-') + 1).replaceAll('*', '');
          dialogResult.PayMethod.LastFour = lastFour;
          dialogResult.PayMethod.PaymentAccountType = PaymentAccountType.Banking;
          dialogResult.PayMethod.Description = `${dialogResult.PayMethod.PaymentAccountType} ending in ${dialogResult.PayMethod.LastFour}`;
          this.dialogRef.close(dialogResult);
        }
      });
    } else {
      this.bankAccountForm.markAllAsTouched();
      const errorMessage = this.storage.getSession(StorageEnum.GlobalVars).formError;
      this.showFormErrorLabel(errorMessage);
    }
  }

  onCancelClick (): void {
    const dialogResult = new PaymentDialogResult();
    dialogResult.Type = 'Cancel';
    dialogResult.PayMethod.ReferenceId = '';
    this.dialogRef.close(dialogResult);
  }

  validateBankAccount (control: AbstractControl): ValidationErrors | null {
    this.showPrefSection = true;
    const accountNumRegex = /^[0-9]*$/;
    const value = control.value;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!value) {
      return { required: true };
    }
    if (!accountNumRegex.test(value)) {
      return { invalidBankAccount: true };
    }
    if (!/^\d{3,19}$/.test(value)) {
      return { invalidBankAccount: true };
    }
    return null;
  }

  checkForDuplicateNickName (): void {
    const nickName = this.bankAccountForm.get('nickName');
    if (nickName) {
      const nickNameValue = this.bankAccountForm.get('nickName')?.value;
      if (this.isDuplicateNickName(nickNameValue)) {
        nickName.setErrors({ duplicateNickName: true });
      }
    }
  }

  isDuplicateNickName (nickNameValue: string): boolean {
    if (nickNameValue) {
      if (this.prefferedPayMethods) {
        nickNameValue = nickNameValue.replace(/\s\s+/g, ' '); // double spaces and empty lines, etc.
        const recordFound = this.prefferedPayMethods.find(p => p.Nickname?.trim() === nickNameValue.trim());
        if (recordFound) {
          return true;
        }
      }
    }
    return false;
  }

  checkForDuplicate (): void {
    const account = this.bankAccountForm.get('accountNumber');
    if (account) {
      const accountNumber = this.bankAccountForm.get('accountNumber')?.value;
      if (this.isAccountAlreadySaved(accountNumber)) {
        account.setErrors({ duplicatePayMethod: true });
        this.showPrefSection = false;
      } else {
        this.showPrefSection = true;
      }
    }
  }

  isAccountAlreadySaved (acctNumber: string): boolean {
    if (!this.prefferedPayMethods) {
      return false;
    } else {
      const foundAcct = this.prefferedPayMethods.find(p => p.LastFour === acctNumber?.toString().trim().slice(-4) && p.PaymentAccountType === PaymentAccountType.Banking);
      if (foundAcct) {
        return true;
      } else {
        return false;
      }
    }
  }

  validateBankRoutingNumber (control: AbstractControl): ValidationErrors | null {
    const routingNumRegex = /^[0-9]*$/;
    const value = control.value;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!value) {
      return { required: true };
    }
    if (!routingNumRegex.test(value)) {
      return { invalidRoutingNumber: true };
    }
    if (!/^\d{9}$/.test(value)) {
      return { invalidRoutingNumber: true };
    }
    return null;
  }

  validateNickname (control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value.length > 45) {
      return { invalidNickname: true };
    }
    const nicknamePattern = /^[A-Za-z0-9 ]*$/;
    if (!nicknamePattern.test(value)) {
      return { invalidCharacters: true };
    }
    return null;
  }

  showFormErrorLabel (errorMessage: string): void {
    this.formErrorMessage = errorMessage;
    setTimeout(() => {
      const errorEl = document.getElementById('formErrorLabel');
      errorEl?.focus();
    }, 10);
  }

  toggleCheckImage (): void {
    this.showBankAccountCheckImage = !this.showBankAccountCheckImage;
    const findAccountbutton = document.getElementById('findAccount');
    const findAccountButtonAriaExpandedValue = findAccountbutton?.getAttribute('aria-expanded');
    if (findAccountbutton != null && findAccountButtonAriaExpandedValue != null) {
      if (findAccountButtonAriaExpandedValue === 'true') {
        findAccountbutton.setAttribute('aria-expanded', findAccountButtonAriaExpandedValue.replace('true', 'false'));
      } else {
        findAccountbutton.setAttribute('aria-expanded', findAccountButtonAriaExpandedValue.replace('false', 'true'));
      }
    }
  }

  savePaymentData (): Observable<any> {
    const paymentusReq: PaymentusRequest = this.mapPaymentusBankRequest();
    this.utils.showSpinner();
    return this.paymentusSvc.getPaymentusToken(paymentusReq);
  }

  mapPaymentusBankRequest (): PaymentusRequest {
    const firstName = this.userProfile?.FirstName;
    const lastName = this.userProfile?.LastName;
    let acctNumber = '';
    if (this.userProfile?.Accounts !== undefined && this.userProfile.Accounts.length > 0) {
      acctNumber = this.userProfile.Accounts[0].AccountNumber ?? '';
    }

    const routingNumber = this.bankAccountForm.get('routingNumber')?.value;
    const accountNumber = this.bankAccountForm.get('accountNumber')?.value;
    const accountType = this.bankAccountForm.get('accountType')?.value;
    const setDefault = this.bankAccountForm.get('setDefault')?.value;
    const saveToWallet = this.bankAccountForm.get('saveWallet')?.value;
    const nickName: string = this.bankAccountForm.get('nickName')?.value;

    const bankInfo: PaymentusBankMethod = {
      'account-number': accountNumber,
      'routing-number': routingNumber,
      // eslint-disable-next-line quote-props
      'type': accountType === BankAccountType.Savings ? 'SAV' : 'CHQ',
      'card-holder-name': `${firstName ?? '.'} ${lastName}`
    };
    const request: PaymentusRequest = {
      'payment-method': bankInfo,
      'has-contact-info': saveToWallet,
      'default-flag': setDefault,
      'profile-description': nickName.trim(),
      // eslint-disable-next-line quote-props
      'customer': { 'first-name': firstName, 'last-name': lastName },
      'user-info': { 'login-id': saveToWallet === true ? acctNumber : '' }
    };

    return request;
  }

  saveWalletOnChange (saveToWallet: MatCheckboxChange): void {
    this.isWalletChecked = saveToWallet.checked;
    if (!this.isWalletChecked) {
      this.bankAccountForm.get('setDefault')?.setValue(false);
      this.bankAccountForm.get('nickName')?.setValue('');
    }
  }
}
