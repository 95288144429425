/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from '../../../shared/services/util.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { CmsService } from '../../../shared/services/cms.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Router } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from '../../../shared/services/storage.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { type Observable } from 'rxjs/internal/Observable';
import { type AccountDetail } from '../../../shared/models/account-detail';
import { StorageEnum } from '../../../shared/enums/storage.enum';
import { format, toDate } from 'date-fns';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { PaymentService } from '../../../shared/services/payment.service';
import { ErrorData } from '../../../shared/models/error-data';
import { AutoPayCancel } from '../../../shared/models/autopay-cancel';
import { PaymentAccountType } from '../../../shared/enums/paymentaccounttype.enum';
import { CTA, SuccessData } from '../../../shared/models/success-data.model';

@Component({
  selector: 'app-autopay-cancel',
  standalone: true,
  imports: [
    MatDividerModule,
    MatButtonModule
  ],
  templateUrl: './autopay-cancel.component.html',
  styleUrl: './autopay-cancel.component.scss'
})
export class AutopayCancelComponent {
  pageReady = false;
  accountDetail: AccountDetail;
  pageLabels$!: Observable<any>;
  pageLabels: any = {};
  apiError: string = '';
  formErrorMessage: string = '';
  apiErrors: any[] = [];

  constructor (
    private readonly utils: UtilService,
    private readonly cms: CmsService,
    private readonly router: Router,
    private readonly storage: StorageService,
    private readonly payment: PaymentService
  ) {
    this.accountDetail = this.storage.getSession(StorageEnum.AccountDetail);
  }

  ngOnInit (): void {
    this.cms.getContent('oas-autopay-change-cancel', 'AutoPayChangeCancel').subscribe(data => {
      this.pageLabels = data;
    });
    this.pageReady = true;
    this.utils.hideSpinner();
  }

  cancelAutoPay (): void {
    const today = format(new Date().toString(), 'MM/dd/yyyy');
    const billDueDate = format(toDate(this.accountDetail.CurrentBalanceDueDate!).toString(), 'MM/dd/yyyy');
    const autoPayEnrollDate = format(toDate(this.accountDetail.AutoPay!.StartDate!).toString(), 'MM/dd/yyyy');
    // if auto pay enrollment date is today or current bill due date is today not eligible
    if (billDueDate === today) {
      this.setErrorDataEnrolledSameBillDate('errorAutoPaySameBillDate');
      void this.router.navigateByUrl('/error-page');
    } else if (autoPayEnrollDate === today) {
      this.setErrorDataEnrolledSameDay('errorAutoPayEnrolledSameDay');
      void this.router.navigateByUrl('/error-page');
    } else {
      this.utils.showSpinner();
      const autoPayCancelRequest = new AutoPayCancel();
      autoPayCancelRequest.AccountNumber = this.accountDetail.AccountNumber;
      autoPayCancelRequest.RecurringScheduleId = this.accountDetail.AutoPay?.RecurringScheduleId;
      autoPayCancelRequest.CustomerClass = this.accountDetail.CustomerClass;

      if (this.accountDetail.AutoPay?.PaymentAccountType === PaymentAccountType.Banking) {
        this.payment.cancelAutoPayBank(autoPayCancelRequest).subscribe({
          error: (e) => {
            this.setErrorDataEnrolledGenericError('genericError');
            void this.router.navigateByUrl('/error-page');
          },
          next: (data: any) => {
            this.setSuccessData();
            void this.router.navigateByUrl('/payment-success');
          }
        });
      } else {
        this.payment.cancelAutoPayCard(autoPayCancelRequest).subscribe({
          error: (e) => {
            this.setErrorDataEnrolledGenericError('genericError');
            void this.router.navigateByUrl('/error-page');
          },
          next: (data: any) => {
            this.setSuccessData();
            void this.router.navigateByUrl('/payment-success');
          }
        });
      }
    }
  }

  setSuccessData (): void {
    const successLabels = this.pageLabels.autoPayCancelSuccess.value.data;
    const successData = new SuccessData();
    successData.Header = successLabels.header;
    successData.Subheader = successLabels.subheaderText;
    const acctCTA = successLabels.ctaList.find((cta: any) => {
      return cta.ctaId === 'makePayment';
    });
    const cta = new CTA();
    cta.Label = acctCTA.ctaLabel;
    cta.AriaLabel = acctCTA.ctaAriaLabel;
    cta.Link = acctCTA.ctaUrl;
    successData.CTAList.push(cta);
    successData.Details = [];
    this.storage.setSession(StorageEnum.SuccessData, successData);
  }

  setErrorDataEnrolledSameDay (errorCode: string): void {
    const errorDetail = new ErrorData();
    errorDetail.ErrorCode = errorCode;
    errorDetail.Header = this.pageLabels.errors[1].error.value.data.header;
    errorDetail.SubheaderTitle = this.pageLabels.errors[1].error.value.data.subheaderTitle;
    errorDetail.Subheader = this.pageLabels.errors[1].error.value.data.subheaderText;
    errorDetail.CtaButton.Label = this.pageLabels.errors[1].error.value.data.ctaList[0].ctaLabel;
    errorDetail.CtaButton.Link = this.pageLabels.errors[1].error.value.data.ctaList[0].ctaUrl;
    errorDetail.CtaButton.AriaLabel = this.pageLabels.errors[1].error.value.data.ctaList[0].ctaAriaLabel;
    this.storage.setSession(StorageEnum.ErrorData, errorDetail);
  }

  setErrorDataEnrolledSameBillDate (errorCode: string): void {
    const errorDetail = new ErrorData();
    errorDetail.ErrorCode = errorCode;
    errorDetail.Header = this.pageLabels.errors[0].error.value.data.header;
    errorDetail.SubheaderTitle = this.pageLabels.errors[0].error.value.data.subheaderTitle;
    errorDetail.Subheader = this.pageLabels.errors[0].error.value.data.subheaderText;
    errorDetail.CtaButton.Label = this.pageLabels.errors[0].error.value.data.ctaList[0].ctaLabel;
    errorDetail.CtaButton.Link = this.pageLabels.errors[0].error.value.data.ctaList[0].ctaUrl;
    errorDetail.CtaButton.AriaLabel = this.pageLabels.errors[0].error.value.data.ctaList[0].ctaAriaLabel;
    this.storage.setSession(StorageEnum.ErrorData, errorDetail);
  }

  setErrorDataEnrolledGenericError (errorCode: string): void {
    const errorDetail = new ErrorData();
    errorDetail.ErrorCode = errorCode;
    errorDetail.Header = this.pageLabels.errors[2].error.value.data.header;
    errorDetail.SubheaderTitle = '';
    errorDetail.Subheader = this.pageLabels.errors[2].error.value.data.subheaderText;
    errorDetail.CtaButton.Label = this.pageLabels.errors[2].error.value.data.ctaList[0].ctaLabel;
    errorDetail.CtaButton.Link = this.pageLabels.errors[2].error.value.data.ctaList[0].ctaUrl;
    errorDetail.CtaButton.AriaLabel = this.pageLabels.errors[2].error.value.data.ctaList[0].ctaAriaLabel;
    this.storage.setSession(StorageEnum.ErrorData, errorDetail);
  }
}
