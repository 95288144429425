/* eslint-disable @typescript-eslint/no-non-null-assertion */
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentMethod, type PaymentMethodResponse } from '../models/payment-method';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { map, Observable, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment.development';
import { FuturePayment, type CardPayment, type PaymentResponse } from '../models/payment';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from './storage.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from './util.service';
import { PaymentAccountType } from '../enums/paymentaccounttype.enum';
import { type AutoPayCancel } from '../models/autopay-cancel';
import { Person } from '../models/person';
import { StorageEnum } from '../enums/storage.enum';

@Injectable({
  providedIn: 'root'
})

export class PaymentService {
  constructor (
    private readonly http: HttpClient,
    private readonly utilService: UtilService,
    private readonly storage: StorageService
  ) { }

  getPreferredPayments (acctNumber?: string): Observable<PaymentMethodResponse> {
    const url = environment.OASUrl(false) + '/payment/preferred-payments' + '?accountNumber=' + acctNumber;
    const res = this.http.get(url).pipe(
      map((data: PaymentMethodResponse) => {
        data.PaymentMethods?.forEach(pm => {
          if (this.isCardBankDigital(pm.PaymentAccountType) !== 'DIGITAL') {
            pm.Description = `${pm.PaymentAccountType} ending in ${pm.LastFour}`;
          } else {
            pm.Description = pm.PaymentAccountType?.toString();
          }
        });
        return data;
      })
    );
    return res;
  }

  getPaymentData (acctNumber?: string): Observable<PaymentMethod> {
    const url = environment.OASUrl(false) + '/payment/payments-data' + '?accountNumber=' + acctNumber;
    const res = this.http.get(url).pipe(
      map((data: any) => {
        data.PaymentMethods?.forEach((pm: PaymentMethod) => {
          if (this.isCardBankDigital(pm.PaymentAccountType) !== 'DIGITAL') {
            pm.Description = `${pm.PaymentAccountType?.toString()} ending in ${pm.LastFour}`;
          } else {
            pm.Description = pm.PaymentAccountType?.toString();
          }
        });
        return data;
      })
    );
    return res;
  }

  updatePreferredPayment (prefPaymentData: PaymentMethod): Observable<any> {
    const url = environment.OASUrl(false) + '/payment/change-preferred-payment';
    const res = this.http.post(url, prefPaymentData);
    return res.pipe(
      tap((data: any) => {
        if (data) {
          return res;
        } else {
          return throwError(() => new Error('Update Preferred Payment error: Failed to update preferred payment'));
        }
      })
    );
  }

  makeCreditCardPayment (paymentData: CardPayment): Observable<PaymentResponse> {
    const url = environment.OASUrl(false) + '/payment/make-card-payment';
    const res = this.http.post(url, paymentData);

    return res.pipe(
      map((data: PaymentResponse) => {
        if (data.Error === null) {
          return data;
        } else {
          throw new Error(data.Error?.ErrorCode);
        }
      })
    );
  }

  makeBankPayment (paymentData: CardPayment): Observable<PaymentResponse> {
    const url = environment.OASUrl(false) + '/payment/make-bank-payment';
    const res = this.http.post(url, paymentData);

    return res.pipe(
      map((data: PaymentResponse) => {
        if (data.Error === null) {
          return data;
        } else {
          throw new Error(data.Error?.ErrorCode);
        }
      })
    );
  }

  postFuturePayment (paymentData: FuturePayment): Observable<PaymentResponse> {
    const url = environment.OASUrl(false) + '/payment/post-future-payment';
    const res = this.http.post(url, paymentData);

    return res.pipe(
      map((data: PaymentResponse) => {
        if (data.Error === null) {
          return data;
        } else {
          throw new Error(data.Error?.ErrorCode);
        }
      })
    );
  }

  cancelAutoPayBank (autoPayCancelData: AutoPayCancel): Observable<any> {
    const url = environment.OASUrl(false) + '/autopay/cancel-bank-autopay';
    const res = this.http.post(url, autoPayCancelData);

    return res.pipe(
      map((data: any) => {
        if (data.Error === null) {
          console.log('autopay cancel response data');
          return data;
        } else {
          throw new Error(data.Error?.ErrorCode);
        }
      })
    );
  }

  cancelAutoPayCard (autoPayCancelData: AutoPayCancel): Observable<any> {
    const url = environment.OASUrl(false) + '/autopay/cancel-card-autopay';
    const res = this.http.post(url, autoPayCancelData);

    return res.pipe(
      map((data: any) => {
        if (data.Error === null) {
          return data;
        } else {
          throw new Error(data.Error?.ErrorCode);
        }
      })
    );
  }

  enrollAutoPay (autoPayData: any): Observable<any> {
    const url = environment.OASUrl(false) + '/autopay/enroll-autopay';
    const res = this.http.post(url, autoPayData);

    return res.pipe(
      map((data: any) => {
        if (data.Error === null) {
          return data;
        } else {
          throw new Error(data.Error?.ErrorCode);
        }
      })
    );
  }

  /**
   * Payment Util Functions
   */
  addNewCardAndBankToPaymethodList (paymethodsList: PaymentMethod[], bankLabel: string, cardLabel: string): void {
    const newBankPayment = new PaymentMethod();
    newBankPayment.ReferenceId = '';
    newBankPayment.Description = bankLabel;
    newBankPayment.PaymentAccountType = PaymentAccountType.Banking;
    paymethodsList?.push(newBankPayment);

    const newCardPayment = new PaymentMethod();
    newCardPayment.ReferenceId = '';
    newCardPayment.Description = cardLabel;
    newCardPayment.PaymentAccountType = PaymentAccountType.Card;
    paymethodsList?.push(newCardPayment);
  }

  /**
   *
   * @param paymethodType Takes PaymentAccountType and gives you general classification
   * @returns string 'BANK', 'CARD', 'DIGITAL', 'CASH', 'UNKNOWN'
   */
  isCardBankDigital (paymethodType: PaymentAccountType | undefined): string {
    switch (paymethodType) {
      case PaymentAccountType.Banking || PaymentAccountType.Banking.toString():
        return 'BANK';
      case PaymentAccountType.Visa || PaymentAccountType.Visa.toString():
      case PaymentAccountType.MasterCard || PaymentAccountType.MasterCard.toString():
      case PaymentAccountType.AmericanExpress || PaymentAccountType.AmericanExpress.toString():
      case PaymentAccountType.Discover || PaymentAccountType.Discover.toString():
      case PaymentAccountType.Card || PaymentAccountType.Card.toString():
        return 'CARD';
      case PaymentAccountType.ApplePay || PaymentAccountType.ApplePay.toString():
      case PaymentAccountType.GooglePay || PaymentAccountType.GooglePay.toString():
      case PaymentAccountType.PayPal || PaymentAccountType.PayPal.toString():
      case PaymentAccountType.Venmo || PaymentAccountType.Venmo.toString():
      case PaymentAccountType.AmazonPay || PaymentAccountType.AmazonPay.toString():
        return 'DIGITAL';
      case PaymentAccountType.Cash || PaymentAccountType.Cash.toString():
        return 'CASH';
      default:
        return 'UNKNOWN';
    }
  }

  sortPaymentMethods (paymethodsList: PaymentMethod[], isLimitedPaymentOptions: boolean = false): PaymentMethod[] {
    let sortedList: PaymentMethod[] = [];
    let newList: PaymentMethod[] = JSON.parse(JSON.stringify(paymethodsList));

    const expiredCards = newList.filter((pm: PaymentMethod) => {
      if (pm.ExpDate) {
        if (this.isCardExpired(pm.ExpDate)) {
          pm.IsExpired = true;
          return true;
        }
        return false;
      } else {
        return false;
      }
    });

    newList = newList.filter((pm: PaymentMethod) => {
      return !expiredCards.includes(pm);
    });

    const defaultPayMethod: PaymentMethod | undefined = newList.find((pm: PaymentMethod) => {
      return pm.IsDefault;
    });

    if (defaultPayMethod) {
      sortedList.push(defaultPayMethod);
      newList = newList.filter((pm: PaymentMethod) => {
        return !pm.IsDefault;
      });
    }

    // nicknamed payments
    const nicknamedPayMethods: PaymentMethod[] = newList.filter((pm: PaymentMethod) => {
      return pm.Nickname;
    });

    sortedList = sortedList.concat(nicknamedPayMethods.sort((a, b) => {
      const nameA = a.Nickname!.toUpperCase();
      const nameB = b.Nickname!.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    }));

    newList = newList.filter((pm: PaymentMethod) => {
      return !nicknamedPayMethods.includes(pm);
    });

    // credit cards on ascending last 4
    const cardsWithLast4 = newList.filter((pm: PaymentMethod) => {
      return this.isCardBankDigital(pm.PaymentAccountType) === 'CARD' && pm.LastFour;
    });

    sortedList = sortedList.concat(cardsWithLast4.sort((a, b) => {
      const nameA = a.LastFour!;
      const nameB = b.LastFour!;

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    }));

    newList = newList.filter((pm: PaymentMethod) => {
      return !cardsWithLast4.includes(pm);
    });

    // expired cards
    sortedList = sortedList.concat(expiredCards);

    // bank ascending on last 4
    const bankWithLast4 = newList.filter((pm: PaymentMethod) => {
      return this.isCardBankDigital(pm.PaymentAccountType) === 'BANK' && pm.LastFour;
    });

    sortedList = sortedList.concat(bankWithLast4.sort((a, b) => {
      const nameA = a.LastFour!;
      const nameB = b.LastFour!;

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    }));
    newList = newList.filter((pm: PaymentMethod) => {
      return !bankWithLast4.includes(pm);
    });

    // digital payments
    const digitalPayMethods = newList.filter((pm: PaymentMethod) => {
      return this.isCardBankDigital(pm.PaymentAccountType) === 'DIGITAL' && !this.utilService.isNullOrUndefined(pm.ReferenceId);
    });

    sortedList = sortedList.concat(digitalPayMethods.sort((a, b) => {
      const nameA = a.PaymentAccountType!;
      const nameB = b.PaymentAccountType!;

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    }));
    newList = newList.filter((pm: PaymentMethod) => {
      return !digitalPayMethods.includes(pm);
    });

    // new card
    const newCardIndex: number = newList.findIndex((pm: PaymentMethod) => {
      return !pm.ReferenceId && pm.PaymentAccountType === PaymentAccountType.Card;
    });
    if (newCardIndex > -1) {
      sortedList.push(newList[newCardIndex]);
      newList.splice(newCardIndex, 1);
    }

    // new bank
    const newBankIndex: number = newList.findIndex((pm: PaymentMethod) => {
      return !pm.ReferenceId && pm.PaymentAccountType === PaymentAccountType.Banking;
    });
    if (newBankIndex > -1) {
      sortedList.push(newList[newBankIndex]);
      newList.splice(newBankIndex, 1);
    }

    // get list of unused digital payments
    const digitalPayTypes = [PaymentAccountType.AmazonPay, PaymentAccountType.ApplePay, PaymentAccountType.GooglePay, PaymentAccountType.PayPal, PaymentAccountType.Venmo]
      .filter(pat => {
        // return digital pay types that aren't in use
        return digitalPayMethods.findIndex(digitalPayment => {
          return digitalPayment.PaymentAccountType === pat;
        }) < 0;
      });

    // create list of paymethods for unused digital pay types
    let unusedDigitalPayments: PaymentMethod[] = [];
    digitalPayTypes.forEach(dp => {
      const newDP = new PaymentMethod();
      newDP.Description = `New ${dp.toString()}`;
      newDP.PaymentAccountType = dp;
      unusedDigitalPayments.push(newDP);
    });

    // remove digital paymethod if it's default pay method
    unusedDigitalPayments = unusedDigitalPayments.filter(dp => {
      return dp.PaymentAccountType !== defaultPayMethod?.PaymentAccountType;
    });

    sortedList = sortedList.concat(unusedDigitalPayments.sort((a, b) => {
      const nameA = a.PaymentAccountType!;
      const nameB = b.PaymentAccountType!;

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    }));

    // digital payments not in use(alphabetical)
    // if LPO set dont show bank, venmo, amazon pay
    const isLPO = isLimitedPaymentOptions;
    if (isLPO) {
      sortedList = sortedList.filter((pm: PaymentMethod) => {
        return pm.PaymentAccountType !== PaymentAccountType.Banking &&
        pm.PaymentAccountType !== PaymentAccountType.Venmo &&
        pm.PaymentAccountType !== PaymentAccountType.AmazonPay;
      });
    }

    const cashPayment = newList.find(dp => {
      return dp.PaymentAccountType === PaymentAccountType.Cash;
    });
    if (cashPayment) {
      sortedList.push(cashPayment);
    }

    return sortedList;
  }

  isCardExpired (expDate: string | undefined): boolean {
    if (!expDate) {
      return false;
    }

    const month = parseInt(expDate.slice(0, 2), 10);
    const year = parseInt(expDate.slice(2), 10);

    const currentDate = new Date();
    const currMonth = currentDate.getMonth() + 1;
    const currYear = currentDate.getFullYear();

    if (year < currYear || (year === currYear && month < currMonth)) {
      return true;
    }
    return false;
  }

  buildCancelFutureDatePaymentRequest (): FuturePayment {
    const accountDetail = this.storage.getSession(StorageEnum.AccountDetail);
    const person = new Person();
    person.Email = accountDetail.Email;
    person.FirstName = accountDetail.FirstName;
    person.LastName = accountDetail.LastName;

    const futurePayment = new FuturePayment();
    futurePayment.Person = person;
    futurePayment.AccountNumber = accountDetail.FuturePayment?.Info?.AccountNumber;
    futurePayment.ActionCode = 'C';
    futurePayment.CustomerClass = accountDetail.CustomerClass;
    futurePayment.Date = accountDetail.FuturePayment?.Info?.PaymentDate;
    futurePayment.Amount = accountDetail.FuturePayment?.Info?.PaymentAmount;
    futurePayment.ReferenceNumber = accountDetail.FuturePayment?.Info?.ReferenceNumber;
    futurePayment.PaymentAccountType = accountDetail.FuturePayment?.PaymentMethod?.PaymentAccountType;
    futurePayment.BankAccountType = accountDetail.FuturePayment?.PaymentMethod?.BankAccountType;
    return futurePayment;
  }
}
