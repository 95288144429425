@if (pageReady) {
    <main class="gs-page">
        <div class="success-container">
            <div class="success-headline-container border">
                <i aria-hidden="true" class="success-icon fa-sharp fa-light fa-circle-check fa-5x"></i>
                <h1>{{data.Header}}</h1>
                <p class="subheader">{{data.Subheader}}</p>
            </div>
            <div class="success-details">
                @if (data.DetailPreMessage ) {
                    <div class="detail-message-container">
                        <p class="detail-message">{{data.DetailPreMessage}}</p>
                    </div>
                    <mat-divider></mat-divider>
                }
                @if (data.PaymentAmount.Value > 0 ) {
                    <div class="payment-amount-container">
                        <h2 class="payment-amount-label">{{data.PaymentAmount.Label}}</h2>
                        <p class="payment-amount"><span class="currencySup">$</span>{{data.PaymentAmount.Value}}</p>
                    </div>
                    <mat-divider></mat-divider>
                }
                @if (data.Details.length > 0) {
                    <div class="detail-container">
                        @for (detail of data.Details; track $index) {
                            <div class="success-detail-row">
                                <h3>{{detail.Label}}</h3>
                                <p>{{detail.Value}}</p>
                            </div>
                            <mat-divider></mat-divider>
                        }
                    </div>
                }
                @if (data.DetailPostMessage) {
                    <p>{{data.DetailPostMessage}}</p>
                }
                @if (data.PostNotificatinoList.length > 0) {
                    <div class="detail-container">
                        @for (pd of data.PostNotificatinoList; track $index) {
                            <p class="gs-post-notification-error">
                                {{data.PostNotificatinoList}}
                            </p>
                        }
                    </div>
                }
                <div class="cta-container">
                    @if (data.LinkList.length > 0) {
                        @for (link of data.LinkList; track $index) {
                            <a class="autopay-link" (click)="nav(link.Link)" [attr.aria-label]="link.AriaLabel">{{link.Label}}</a>
                        }
                    }
                    @if (data.CTAList.length > 0) {
                        @for (cta of data.CTAList; track $index) {
                            <button role="link" mat-flat-button color="primary" [attr.aria-label]="cta.AriaLabel" (click)="nav(cta.Link)" class="gs-form-button-primary">{{cta.Label}}</button>
                        }
                    }
                </div>
            </div>
        </div>
    </main>
}