/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.development';
import { BehaviorSubject } from 'rxjs';
import { PaymentMethod } from '../models/payment-method';
import { PaymentAccountType } from '../enums/paymentaccounttype.enum';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  isLoading = false;
  showLoadingMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isNullOrWhitespace (str: string | null | undefined): boolean {
    return str === null || str === undefined || str.trim() === '';
  }

  isNullOrUndefined (value: any): boolean {
    return value === null || value === undefined;
  }

  consoleGroup (title: string, msg: any): void {
    if (environment.enableLogging) {
      console.groupCollapsed(title);
      console.log(msg);
      console.groupEnd();
    }
  }

  consoleLog (msg: any): void {
    if (environment.enableLogging) {
      console.log(msg);
    }
  }

  showSpinner (showMessage = false): void {
    if (typeof document !== 'undefined') {
      const spinner = document.getElementById('spinner');
      spinner?.classList.add('show');
    }
    this.isLoading = true;
    if (showMessage) {
      this.showLoadingMessage.next(true);
    }
  }

  hideSpinner (): void {
    if (typeof document !== 'undefined') {
      const spinner = document.getElementById('spinner');
      spinner?.classList.remove('show');
      this.isLoading = false;
      this.showLoadingMessage.next(false);
    }
  }

  /**
   * Function to check if screen width is lower than passed in width.
   * @param mobileWidth maximum width to be considered mobile(non-inclusive)
   * @returns true if screen size is smaller than mobileWidth, false otherwise
   */
  isMobile (mobileWidth: number): boolean {
    if (typeof window !== 'undefined') {
      return window.screen.width < mobileWidth;
    } else {
      return false;
    }
  }

  // https://stackoverflow.com/a/21617574
  getCreditCardBrandFromNumber (currVal: string): PaymentAccountType {
    // American Express
    const amexRegex = /^3[47][0-9]{0,}$/; // 34, 37
    // Visa
    const visaRegex = /^4[0-9]{0,}$/; // 4
    // MasterCard
    const mastercardRegex = /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$/; // 2221-2720, 51-55
    const maestroRegex = /^(5[06789]|6)[0-9]{0,}$/; // always growing in the range: 60-69, started with / not something else, but starting 5 must be encoded as mastercard anyway
    // Discover
    const discoverRegex = /^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$/; // 6011, 622126-622925, 644-649, 65

    // get rid of anything but numbers
    currVal = currVal.replace(/\D/g, '');

    // checks per each, as their could be multiple hits
    // fix: ordering matter in detection, otherwise can give false results in rare cases
    let selBrand = PaymentAccountType.Unknown;
    if (currVal.match(amexRegex)) {
      selBrand = PaymentAccountType.AmericanExpress;
    } else if (currVal.match(visaRegex)) {
      selBrand = PaymentAccountType.Visa;
    } else if (currVal.match(mastercardRegex)) {
      selBrand = PaymentAccountType.MasterCard;
    } else if (currVal.match(discoverRegex)) {
      selBrand = PaymentAccountType.Discover;
    } else if (currVal.match(maestroRegex)) {
      if (currVal[0] === '5') { // started 5 must be mastercard
        selBrand = PaymentAccountType.MasterCard;
      } else {
        selBrand = PaymentAccountType.Unknown; // maestro is all 60-69 which is not something else, thats why this condition in the end
      }
    }

    return selBrand;
  }
}
