@if (pageReady) {
<main class="gs-page">
    <div class="autopay-container">
        <div class="headline-container">
            <div class="headline-content">
                <h1 class="headline h1-lg">{{ pageLabels.header }}</h1>
                <div>Filler headline content container</div>
            </div>
        </div>
        <div class="notification-container">
            @if (formErrorMessage != '') {
            <div id="formErrorLabel" tabindex="0" class="gs-post-notification-error">
                <i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                <span [innerHTML]="formErrorMessage"></span>
            </div>
            }

            @if(showFDPWillBeDraftedPreNotification) {
                <p class="gs-post-notification"><i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                {{pageLabels.fdpWillBeDraftedPreNofitication}}</p>
            }
            @if(showFDPWillBeCanceledPreNotification) {
                <p class="gs-post-notification"><i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                {{pageLabels.fdpWillBeCanceledPreNofitication}}</p>
            }
            @if(showLimitedPaymentOptionPreNotification) {
                <p class="gs-post-notification"><i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                {{pageLabels.limitedPaymentPreNotification}}</p>
            }
            @if(showBillDueTodayPreNotification) {
                <p class="gs-post-notification"><i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                {{pageLabels.billDueTodayPreNofitication}}</p>
            }
        </div>
        <div class="gs-content-container">
            <p>{{ pageLabels.description }}</p>
            <form class="autopay-form" [formGroup]="autopayForm">
                <div class="pay-method">
                    <h2>{{ pageLabels.payMethodInputHeader }}</h2>
                    <mat-form-field class="payment-list" appearance="outline" color="primary">
                        <mat-label>{{ pageLabels.payMethodLabel }}</mat-label>
                        <mat-select formControlName="paymethod" (selectionChange)="paymethodChanged()">
                            @for (method of paymethods; track method) {
                            <mat-option class="paymethod-option" [value]="method">
                                {{method.Nickname ? method.Nickname : method.Description}}
                                @if (method.IsExpired) {
                                <span class="paymethod-expired">{{ pageLabels.payMethodExpiredText }}</span>
                                }
                            </mat-option>
                            }
                        </mat-select>
                        @if (autopayForm.get('paymethod')?.hasError('required')) {
                        <mat-error id="payMethodReqError">{{ pageLabels.payMethodRequiredError }}</mat-error>
                        }
                    </mat-form-field>
                </div>
                <div class="terms-container">
                    <mat-checkbox color="primary" formControlName="terms">{{ pageLabels.termsConditionsText }}
                        <a href="{{pageLabels.termsConditionsLinkUrl}}" target="_blank">{{ pageLabels.termsConditionsLinkText}} <i aria-hidden="true" class="fa-solid fa-arrow-up-right-from-square"></i></a>
                    </mat-checkbox>
                    @if (autopayForm.get('terms')?.touched && autopayForm.get('terms')?.hasError('required')) {
                        <mat-error id="termConditionsError" class="gs-error terms-conditoins-error">{{ pageLabels.termsRequiredError}}</mat-error>
                    }
                </div>
                <div class="button-container">
                    <button role="link" class="gs-form-button-primary" mat-flat-button color="primary" (click)="enrollAutopay()">{{ pageLabels.enrollCtaBtnText }}</button>
                </div>
            </form>
        </div>
    </div>
</main>
}